import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Sidebar from "../components/Sidebar"
import PostTemplateDetails from "../components/PostTemplateDetails"

class PostTemplate extends React.Component {
  render() {
    const { title } = this.props.data.site.siteMetadata
    const { title: postTitle } = this.props.data.blogPost.frontmatter

    return (
      <Layout>
        <div>
          <Helmet>
            <title>{`${postTitle} | ${title}`}</title>
          </Helmet>
          <Sidebar {...this.props} />
          <PostTemplateDetails {...this.props} />
        </div>
      </Layout>
    )
  }
}

export default PostTemplate

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
        }
        author {
          name
          email
          twitter
          github
        }
      }
    }
    blogPost: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      content: rawMarkdownBody
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
