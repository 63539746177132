import React from "react"
// import { Link } from 'gatsby'
import moment from "moment"
import ReactMarkdown from "react-markdown"
import Prism from "prismjs"
import "./style.scss"

import CodeBlock from "../CodeBlock"

class PostTemplateDetails extends React.Component {
  componentDidMount() {
    Prism.highlightAll()
  }

  render() {
    const post = this.props.data.blogPost

    // const homeBlock = (
    //   <div>
    //     <Link className="post-single__home-button" to="/">
    //       All Articles
    //     </Link>
    //   </div>
    // )

    return (
      <div className="content">
        <div className="content__inner">
          <div className="post-single">
            <h1 className="post-single__title">{post.frontmatter.title}</h1>
            <div className="post-single__body">
              <ReactMarkdown
                source={post.content}
                renderers={{ code: CodeBlock }}
              />
            </div>
            <div className="post-single__date">
              <em>
                Published{" "}
                {moment(post.frontmatter.published).format("D MMM YYYY")}
              </em>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PostTemplateDetails
